<template>
    <div class="home">
    <v-layout row wrap class="d-flex">
      <v-flex xs12 md12 lg12 class="mb-5">
        <h1 class="f-alfa primary--text "><router-link to="/">Dashboard</router-link> / Promotions</h1>
      </v-flex>
      <v-flex xs12 lg3>
        <div class="menu-item" @click="vouchers">
          <h3 class="primary--text fw-bold">Vouchers</h3>
        </div>
        <div class="menu-item mt-10">
          <h3 class="primary--text fw-bold">Influencers</h3>
        </div>
        <div class="menu-item-disabled mt-10">
          <h3 class="primary--text fw-bold">Performance</h3>
        </div>
        <div class="menu-item-disabled mt-10">
          <h3 class="primary--text fw-bold">Active Promotions</h3>
        </div>
      </v-flex>
      </v-layout>
      </div>
</template>

<style scoped>
.menu-item {
  cursor: pointer;
  border: 2px solid #ba9d73;
  border-radius: 10px;
  padding: 20px 10px;
  box-shadow: 5px 5px 10px rgba(186, 157, 115, 1);
}
.menu-item-disabled {
  cursor: not-allowed;
  border: 2px solid #ba9d73;
  border-radius: 10px;
  padding: 20px 10px;
  box-shadow: 5px 5px 10px rgba(186, 157, 115, 1);
}
</style>

<script>
export default {
    methods : {
        vouchers(){
            this.$router.push('/promotions/vouchers')
        },
        list(){
            this.$router.push('/promotions/influencers')
        }
    }
}
</script>